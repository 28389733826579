const _mimetypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "image/png",
  "image/jpeg",
  "image/x-png",
  "image/apng",
  "application/vnd.ms-excel",
  "application/zip",
  "application/x-7z-compressed",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/x-rar-compressed",
  "text/csv",
  "text/plain",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
  "image/tiff",
  "text/x-vcard",
  "image/jpg",
  "image/svg+xml",
  "text/xml",
] as const;

export type FileMimeType = (typeof _mimetypes)[number];
export const basicImageAndPdfMimeType: FileMimeType[] = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
];

export const basicImportMimeType: FileMimeType[] = ["text/csv", "application/pdf"];

export function idFileTypeToMimeType(id: number): FileMimeType {
  switch (id) {
    case 1:
      return "application/pdf";
    case 2:
      return "application/msword";
    case 3:
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case 4:
      return "application/vnd.oasis.opendocument.text";
    case 5:
      return "image/png";
    case 6:
      return "image/jpeg";
    case 7:
      return "image/x-png";
    case 8:
      return "application/vnd.ms-excel";
    case 9:
      return "application/zip";
    case 10:
      return "application/x-7z-compressed";
    case 11:
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case 12:
      return "application/x-rar-compressed";
    case 13:
      return "text/csv";
    case 14:
      return "text/plain";
    case 15:
      return "application/vnd.oasis.opendocument.spreadsheet";
    case 16:
      return "application/vnd.oasis.opendocument.presentation";
    case 17:
      return "image/tiff";
    case 18:
      return "text/x-vcard";
    case 19:
      return "image/jpg";
    case 20:
      return "image/svg+xml";
    case 21:
      return "text/xml";
    default:
      throw new RangeError(`idFileType ${id} is not matching any fileType`);
  }
}

export function isAnImage(mimetype: string): boolean {
  return (
    mimetype === "image/jpeg" ||
    mimetype === "image/png" ||
    mimetype === "image/apng" ||
    mimetype === "image/jpg" ||
    mimetype === "image/x-png" ||
    mimetype === "image/tiff" ||
    mimetype === "image/svg+xml"
  );
}
